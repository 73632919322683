<template>
  <div class="content">
    <div class="infoCollectionWrap">
      <div class="infoTitle">添加乘机人</div>
      <div class="infoContent">
        <div class="infoFill">
          <p class="infoFillTitle">姓名</p>
          <input
            v-model="form.truename"
            type="text"
            class="infoFillInp"
            placeholder="与乘机证件一致"
            @input="changeName($event)"
          >
        </div>
        <div class="van-hairline--bottom" />
        <div class="infoFill">
          <p class="infoFillTitle">姓名拼音</p>
          <input v-model="form.pinyin" type="text" disabled class="infoFillInp" placeholder="输入姓名拼音">
        </div>
        <div class="van-hairline--bottom" />
        <div v-if="PassportDisplayTimeInfo" class="infoFill">
          <p class="infoFillTitle">性别</p>
          <input v-model="selSexText" type="text" class="infoFillInp" placeholder="选择性别" @click="ChoiceSexId">
          <van-icon name="arrow" class="iconStyle" />
        </div>
        <div v-if="PassportDisplayInfo" class="van-hairline--bottom" />
        <div v-if="PassportDisplayTimeInfo" class="infoFill">
          <p class="infoFillTitle">出生日期</p>
          <input
            v-model="form.birthday"
            type="text"
            class="infoFillInp"
            placeholder="选择出生年月"
            @click="ChoiceDateBirthId(0)"
          >
          <van-icon name="arrow" class="iconStyle" />
        </div>
        <div v-if="PassportDisplayInfo" class="van-hairline--bottom" />
        <div class="infoFill">
          <p class="infoFillTitle">证件类型</p>
          <input v-model="selCardText" type="text" class="infoFillInp" placeholder="选择证件类型" @click="ChoiceCardId">
          <van-icon name="arrow" class="iconStyle" />
        </div>
        <div class="van-hairline--bottom" />
        <div class="infoFill">
          <p class="infoFillTitle">证件号码</p>
          <input v-model="form.cardNumber" type="text" class="infoFillInp" placeholder="请输入证件号码">
        </div>
        <div class="van-hairline--bottom" />
        <div class="infoFill">
          <p class="infoFillTitle">证件有效期</p>
          <input
            v-model="form.card_validity"
            type="text"
            class="infoFillInp"
            placeholder="选择证件有效期"
            @click="ChoiceDateBirthId(1)"
          >
          <van-icon name="arrow" class="iconStyle" />
        </div>
        <div v-if="PassportDisplayInfo" class="van-hairline--bottom" />
        <div v-if="PassportDisplayInfo" class="infoFill">
          <p class="infoFillTitle">国籍</p>
          <input v-model="form.Nationality" type="text" class="infoFillInp" placeholder="请填写国籍">
        </div>
        <div v-if="PassportDisplayInfo" class="van-hairline--bottom" />
        <div class="infoFill">
          <p class="infoFillTitle">境内手机</p>
          <input v-model="form.mobile" type="text" class="infoFillInp" placeholder="用于接受航变信息">
        </div>
        <div class="van-hairline--bottom" />
      </div>
      <div v-if="isShowDel" class="delOpp">
        <div class="delText" @click="delOpp">
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-30/21/yuelvhuirMxyn28MXJ1609336597.png"
            alt=""
          >
          <p>删除联系人</p>
        </div>
      </div>
      <div class="SaveBtnWrap">
        <div @click="saveInfo">保存</div>
      </div>
    </div>

    <!--        证件类型弹窗-->
    <van-action-sheet v-model="isShowSelCardType" title="请选择证件类型">
      <div class="cardListStyle">
        <p v-for="(item,index) in cardList" :key="index" @click="selCardIds(item)">{{ item.value }}</p>
      </div>
    </van-action-sheet>
    <!--        性别弹窗-->
    <van-action-sheet v-model="isShowSex" title="请选择性别">
      <div class="cardListStyle">
        <p v-for="(item,index) in sexList" :key="index" @click="SelSexIds(item)">{{ item.value }}</p>
      </div>
    </van-action-sheet>
    <!--        出生年月弹窗-->
    <van-action-sheet v-model="isShowDateBirth">
      <div class="cardListStyle">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="请选择出生年月"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="confirmDateBirth"
          @cancel="cancelDateBirth"
        />
      </div>
    </van-action-sheet>
    <!--        身份证有效期弹窗-->
    <van-action-sheet v-model="isShowDateValidity">
      <div class="cardListStyle">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="请选择证件有效期"
          :min-date="minDate"
          :max-date="maxDate1"
          @confirm="confirmDateBirth"
          @cancel="cancelDateBirth"
        />
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from 'vue'
import { ActionSheet, DatetimePicker, Icon, List, Loading, Sidebar, SidebarItem, Tab, Tabs, Toast, TreeSelect } from 'vant'

Vue.use(Tab)
  .use(Tabs)
  .use(Loading)
  .use(Toast)
  .use(ActionSheet)
  .use(Sidebar)
  .use(SidebarItem)
  .use(TreeSelect)
  .use(List)
  .use(Icon)
  .use(DatetimePicker)

import {
  getCardTypeList,
  setTakeOpp,
  setOppInfo,
  delOppInfo
} from '@/services/comeOn.js'
import { pinyin } from '@/utils/const.js'
import { isCardId, isMobile } from '@/utils/common/validate'

export default {
  name: 'AddOpp',
  data() {
    return {
      form: {
        mid: '',
        truename: '', // 姓名
        pinyin: '', // 姓名拼音
        cardNumber: '', // 证件号码
        mobile: '', // 境内手机号
        cardType: 1, // 选中证件id
        sex: 1, // 选中的性别id
        selDateBirthText: '', // 出生年月
        selCardValidity: '', // 证件有效期
        Nationality: ''// 国籍
      },
      selCardText: '身份证', // 选中证件名称
      selSexText: '男', // 选中的性别
      cardList: [], // 证件列表
      isShowSelCardType: false, // 证件类型弹窗
      PassportDisplayInfo: false, // 护照显示信息
      sexList: [{
        key: 0,
        value: '女'
      }, {
        key: 1,
        value: '男'
      }], // 性别
      isShowSex: false, // 性别弹窗
      isShowDateBirth: false, // 出生年月弹窗
      minDate: new Date(1900, 1, 1), // 可选最小年月日
      maxDate: new Date(), // 最大可选年月日
      currentDate: new Date(), // 选中年月日
      maxDate1: new Date(2099, 12, 30),
      PassportDisplayTimeInfo: false, // 出生日期/证件有效期/性别
      id: false,
      isShowDateValidity: false,
      isShowDel: false// 删除联系人
    }
  },
  created() {

  },
  mounted() {
    this.getCardIdList()// get证件类型
    this.id = this.$route.query ? this.$route.query.id : false// 编辑用户id
    if (this.id === 'undefined') {
      this.getOppInfo()// get用户信息
      this.isShowDel = true
    }
  },
  methods: {
    // get证件类型
    getCardIdList() {
      getCardTypeList().then(res => {
        if (Number(res.code) === 200) {
          this.cardList = res.data
        }
      })
    },
    // 选择证件弹窗
    ChoiceCardId() {
      this.isShowSelCardType = true
    },
    // 选择证件
    selCardIds(item) {
      this.form.selCardId = item.key
      this.selCardText = item.value
      if (item.key === 2) { // 护照
        this.PassportDisplayInfo = true
        this.PassportDisplayTimeInfo = true
      } else if (item.key === 3 || item.key === 5 || item.key === 4 || item.key === 9) {
        this.PassportDisplayInfo = false
        this.PassportDisplayTimeInfo = true
      } else {
        this.PassportDisplayInfo = false
        this.PassportDisplayTimeInfo = false
      }
      this.isShowSelCardType = false
    },
    // 姓名变化
    changeName(event) {
      this.form.pinyin = this.chineseToPinYin(event.currentTarget.value)
    },
    // 选中性别弹窗
    ChoiceSexId(item) {
      this.isShowSex = true
    },
    // 选择性别
    SelSexIds(item) {
      this.form.sex = item.key
      this.selSexText = item.value
      this.isShowSex = false
    },
    // 选择出生年月弹窗  //选择证件有效期
    ChoiceDateBirthId(type) {
      if (type === 0) {
        this.isShowDateBirth = true
      } else if (type === 1) {
        this.isShowDateValidity = true
      }
      this.timeType = type
    },
    formatDates(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    // 确认年月日
    confirmDateBirth(value) {
      if (this.timeType === 0) {
        this.form.birthday = this.formatDates(value)
        this.isShowDateBirth = false
      } else if (this.timeType === 1) {
        this.form.card_validity = this.formatDates(value)
        this.isShowDateValidity = false
      }
    },
    // 取消年月日
    cancelDateBirth() {
      if (this.timeType === 0) {
        this.isShowDateBirth = false
      } else if (this.timeType === 1) {
        this.isShowDateValidity = false
      }
    },
    // 保存信息
    saveInfo() {
      this.form.mid = window.localStorage.getItem('uid')
      if (this.form.cardType === '') {
        Toast('请选择证件类型')
        return false
      } else if (this.form.truename === '') {
        Toast('请输入姓名')
        return false
      } else if (this.form.cardNumber === '') {
        Toast('请输入证件号码')
        return false
      } else if (!isCardId(this.form.cardNumber)) {
        Toast('请输入正确的证件号码')
        return false
      } else if (this.form.mobile === '') {
        Toast('请输入手机号')
        return false
      } else if (!isMobile(this.form.mobile)) {
        Toast('请输入正确的手机号')
        return false
      } else if (this.form.card_validity === '') {
        Toast('请选择证件有效期')
        return false
      }
      if (this.form.cardType !== 1) {
        if (this.form.birthday === '') { // 证件类型不是身份证时必填
          Toast('请选择出生日期')
          return false
        }
      }
      setTakeOpp(this.form).then(res => {
        if (Number(res.code) === 200) {
          Toast(res.msg)
          this.$router.go(-1)
        }
      })
    },
    // 转拼音
    chineseToPinYin(l1) {
      var l2 = l1.length
      var I1 = ''
      var reg = new RegExp('[a-zA-Z0-9]')
      for (var i = 0; i < l2; i++) {
        var val = l1.substr(i, 1)
        var name = this.arraySearch(val, pinyin)
        if (reg.test(val)) {
          I1 += val
        } else if (name !== false) {
          I1 += name
        }
      }
      I1 = I1.replace(/ /g, '-')
      while (I1.indexOf('--') > 0) {
        I1 = I1.replace('--', '-')
      }
      return I1
    },
    arraySearch(l1) {
      for (var name in pinyin) {
        if (pinyin[name].indexOf(l1) !== -1) {
          return this.ucfirst(name)
        }
      }
      return false
    },
    ucfirst(l1) {
      if (l1.length > 0) {
        var first = l1.substr(0, 1).toUpperCase()
        var spare = l1.substr(1, l1.length)
        return first + spare
      }
    },
    // get用户信息
    getOppInfo() {
      setOppInfo({
        id: this.id
      }).then(res => {
        if (Number(res.code) === 200) {
          this.form.truename = res.data.truename// 姓名
          this.form.pinyin = this.chineseToPinYin(res.data.truename)// 姓名拼音
          if (res.data.sex === 0) {
            this.selSexText = '女'// 性别
          } else if (res.data.sex === 1) {
            this.selSexText = '男'// 性别
          }
          this.form.birthday = res.data.birthday// 出生日期
          this.form.cardNumber = res.data.card_number// 证件号码
          this.form.card_validity = res.data.card_validity// 证件有效期
          this.form.Nationality = res.data.address// 国籍
          this.form.mobile = res.data.mobile// 手机号
          for (let i = 0; i < this.cardList.length; i++) {
            if (this.cardList[i].key === res.data.card_type) {
              this.selCardText = this.cardList[i].value// 证件类型名称
              return
            }
          }
          this.cardType = res.data.card_type// 证件类型id
        }
      })
    },
    // 删除联系人
    delOpp() {
      delOppInfo({
        id: this.id
      }).then(res => {
        if (Number(res.code) === 200) {
          Toast(res.mag)
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.content {
  position: absolute;
  text-align: left;

  .infoCollectionWrap {
    .infoTitle {
      width: 100%;
      height: 44px;
      text-align: center;
      line-height: 44px;
      font-size: 17px;
      color: #333333;
    }

    .infoContent {
      margin-top: 7px;
      background-color: #fff;
      padding: 0 13px;

      .infoFill {
        display: flex;
        height: 54px;
        line-height: 54px;

        .infoFillTitle {
          width: 30%;
          font-size: 14px;
          color: #666666;
        }

        .infoFillInp {
          width: 60%;
          background-color: #fff;
        }

        .iconStyle {
          margin-left: auto;
          line-height: 54px;
        }
      }
    }

    .delOpp {
      display: flex;
      padding: 20px 10px;

      .delText {
        margin-left: auto;
        display: flex;

        img {
          width: 12px;
          height: 12px;
          margin-right: 6px;
        }

        p {
          font-size: 12px;
          color: #999999;
        }
      }
    }

    .SaveBtnWrap {
      margin-top: 35px;
      padding: 33px;

      div {
        width: 100%;
        height: 45px;
        background-color: #E33F44;
        text-align: center;
        line-height: 45px;
        font-size: 17px;
        color: #ffffff;
        border-radius: 7px;
      }
    }
  }

  /*    证件弹窗*/

  .cardListStyle {
    p {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      color: #666666;
      text-align: center;
    }
  }
}
</style>
